import 'owl.carousel2/dist/assets/owl.carousel.css';
import 'owl.carousel2/dist/owl.carousel.js';

$(document).ready(function(){
  $('.owl-carousel').owlCarousel({
    stagePadding: 0,
    items: 1,
    loop:true,
    margin:0,
    singleItem:true,
    autoplay:true,
    autoplayTimeout:3000,
    autoplayHoverPause:true
  });
});